import React, { HTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import {
  BookSortingEnum, BookTypeEnum, CompleteStatusEnum,
} from '~/api/book/bookApiTypes';
import { Dropdown } from '~/atomic/atom/Dropdown';
import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { Select, SelectOption } from '~/atomic/molecula/Select';
import { BookSort } from '~/feature/catalog/filter/BookSort';
import {
  changeBookType,
  changeCatalogFilterIsCanDownload,
  changeCatalogFilterIsOnlyFree, changeCatalogFilterIsWithAbonnement,
  changeCatalogFilterWithAudiobook,
  changeCompleteStatus,
  changeGenres,
  changeSortingType,
} from '~/feature/catalog/filter/catalogFilter.data';
import {
  catalogFilterGenresSelector,
  catalogFilterSelector,
} from '~/feature/catalog/filter/catalogFilter.selector';
import { CatalogFilterTags } from '~/feature/catalog/filter/CatalogFilterTags';
import {
  CatalogOtherFilters,
  CatalogOtherFiltersProps,
} from '~/feature/catalog/filter/CatalogOtherFilters';
import {
  SwitchWithAbonnementFilter,
  SwitchWithAbonnementFilterProps,
} from '~/feature/catalog/filter/SwitchWithAbonnementFilter';
import { SwitchWithAudiobookFilter } from '~/feature/catalog/filter/SwitchWithAudiobookFilter';
import { genres } from '~/feature/genre/genres';
import { nestedGenres } from '~/feature/genre/nestedGenres';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

type FilterProps = {
  onChange?: () => void;
  isShowSorting?: boolean;
  isSlowSwitchWithAudiobook?: boolean;
  isShowTags?: boolean;
  isShowAll?: true;
  sortingOptions?: Record<BookSortingEnum, string>;
} & Pick<SwitchWithAbonnementFilterProps, 'isShowWithAbonnement'>
& Pick<CatalogOtherFiltersProps,
'isShowBookType' |
'isShowOnlyFree' |
'isShowCanDownload' |
'isShowCompleteStatus'
>;

export const CatalogFilter = ({
  onChange,
  isShowAll,
  sortingOptions,
  isShowSorting = isShowAll ?? false,
  isSlowSwitchWithAudiobook = isShowAll ?? false,
  isShowTags = isShowAll ?? false,
  isShowOnlyFree = isShowAll ?? false,
  isShowCanDownload = isShowAll ?? false,
  isShowBookType = isShowAll ?? false,
  isShowCompleteStatus = isShowAll ?? false,
  isShowWithAbonnement = isShowAll ?? false,
  ...props
}: FilterProps & HTMLAttributes<HTMLDivElement>) => {
  const dispatch = useAppDispatch();
  const [isOpenSelect, setOpenSelect] = useState(false);
  const {
    isCanDownload,
    isCanDownloadUrl,
    isOnlyFree,
    isOnlyFreeUrl,
    bookType,
    sortingType,
    completeStatus,
    withAudiobook,
    tags,
    tagSlug,
    isWithAbonnement,
    isAddFilterToParams,
  } = useAppSelector(catalogFilterSelector);
  const selectedGenres = useAppSelector(catalogFilterGenresSelector);
  const isShowOtherFilters = isShowCanDownload || isShowOnlyFree || isShowBookType || isShowCompleteStatus;

  const onChangeGenres = async (value) => {
    if (value.length <= 4 && value.every((genre) => genre.trim().length > 0)) {
      await dispatch(changeGenres(value as string[]));
    }
    if (onChange) onChange();
    setOpenSelect(false);
  };

  const onChangeBookSort = (value: BookSortingEnum) => {
    dispatch(changeSortingType(value));
    if (onChange) onChange();
  };

  const onChangeBookType = async (value) => {
    await dispatch(changeBookType(value as BookTypeEnum));
    if (onChange) onChange();
  };

  const onChangeCompleteStatus = async (value: CompleteStatusEnum) => {
    await dispatch(changeCompleteStatus(value));
    if (onChange) onChange();
  };

  const onChangeWithAudiobook = async (value: boolean) => {
    await dispatch(changeCatalogFilterWithAudiobook(value));
    if (onChange) onChange();
  };

  const onChangeIsOnlyFree = async (value: boolean) => {
    await dispatch(changeCatalogFilterIsOnlyFree(value));
    if (onChange) onChange();
  };

  const onChangeIsCanDownload = async (value: boolean) => {
    await dispatch(changeCatalogFilterIsCanDownload(value));
    if (onChange) onChange();
  };

  const onChangeIsWithAbonnement = async (value: boolean) => {
    await dispatch(changeCatalogFilterIsWithAbonnement(value));
    if (onChange) onChange();
  };

  return (
    <SCFilter {...props}>
      {isSlowSwitchWithAudiobook ? (
        <SwitchWithAudiobookFilter
          withAudiobook={withAudiobook}
          onSwitch={onChangeWithAudiobook}
        />
      ) : null}
      <SCGenreSelect
        open={isOpenSelect}
        onDropdownVisibleChange={(visible) => setOpenSelect(visible)}
        value={selectedGenres}
        onChange={onChangeGenres}
        mode="tags"
        optionLabelProp="label"
        optionFilterProp="label"
        data-has-genre={String(!!selectedGenres.length)}
      >
        {genres.map((genre) => (
          <SelectOption
            key={`genre-option-${genre.slug}`}
            value={String(genre.slug)}
            label={genre.name}
            className={
              nestedGenres.find((mainGenre) => mainGenre.slug === genre.slug)
                ? 'main-genre'
                : 'sub-genre'
            }
          >
            {genre.name}
          </SelectOption>
        ))}
      </SCGenreSelect>
      <SCBlock>
        {isShowWithAbonnement || isShowSorting ? (
          <SCBlock>
            <SwitchWithAbonnementFilter
              isShowWithAbonnement={isShowWithAbonnement}
              onChangeWithAbonnement={onChangeIsWithAbonnement}
              withAbonnement={isWithAbonnement}
            />
            {isShowSorting && (
              <BookSort
                bookSorting={sortingType}
                onChangeBookSort={onChangeBookSort}
                sortingOptions={sortingOptions}
              />
            )}
          </SCBlock>
        ) : null}
        {isShowOtherFilters || isShowTags ? (
          <SCBlock>
            {isShowOtherFilters && (
              <Dropdown
                trigger={['click']}
                placement="bottomCenter"
                overlayStyle={{
                  width: '282px',
                  background: '#fff',
                  border: '1px solid var(--bg-color)',
                  boxSizing: 'border-box',
                  boxShadow: '0px 3px 5px rgba(53, 63, 72, 0.25)',
                  borderRadius: '4px',
                  padding: '20px',
                }}
                overlay={(
                  <CatalogOtherFilters
                    isCanDownload={isCanDownload}
                    isCanDownloadUrl={isCanDownloadUrl}
                    isOnlyFree={isOnlyFree}
                    isOnlyFreeUrl={isOnlyFreeUrl}
                    completeStatus={completeStatus}
                    onChangeCompleteStatus={onChangeCompleteStatus}
                    bookType={bookType}
                    onChangeBookType={onChangeBookType}
                    isShowOnlyFree={isShowOnlyFree}
                    isShowCanDownload={isShowCanDownload}
                    isShowBookType={isShowBookType}
                    isShowCompleteStatus={isShowCompleteStatus}
                    isAddFilterToParams={isAddFilterToParams}
                    onChangeIsOnlyFree={onChangeIsOnlyFree}
                    onChangeIsCanDownload={onChangeIsCanDownload}
                  />
                )}
              >
                <SCButton>
                  Фильтры
                  {' '}
                  <SCArrowDownIcon />
                </SCButton>
              </Dropdown>
            )}
            {isShowTags
            && <CatalogFilterTags currentTagSlug={tagSlug} tags={tags} />}
          </SCBlock>
        ) : null}
      </SCBlock>
    </SCFilter>
  );
};

const SCButton = styled.div`
  color: var(--primary-color);
  font-family: var(--second-font);
  font-weight: normal;
  font-size: 14px;
  border-radius: 24px;
  height: 24px;
  align-items: center;
  display: flex;
  width: max-content;
  cursor: pointer;
  flex-shrink: 0;
`;

const SCGenreSelect = styled(Select)`
  min-height: 44px;
  min-width: 270px;
  flex-grow: 20;

  .ant-select-selection-item-content {
    color: var(--black-color);
  }

  .ant-select-selector {
    min-height: 44px;
    padding-left: 66px;

    &:before {
      position: absolute;
      left: 16px;
      content: 'Жанр';
      font-family: var(--main-font);;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-color);
    }
  }

  .ant-select-selection-item {
    background: var(--yellow-color);
    border: 1px solid var(--yellow-color);
    box-sizing: border-box;
    border-radius: 24px;
  }

  &[data-has-genre="true"] {
    .ant-select-selector {
      padding-left: 16px;

      &:before {
        display: none;
      }
    }
  }
`;

const SCFilter = styled.div`
  --filter-column-gap: 16px;
  --filter-row-gap: 12px;
  
  display: flex;
  align-items: center;
  width: 100%;
  background: var(--white-color);
  border: 1px solid var(--gray-color2);
  box-sizing: border-box;
  box-shadow: 0 1px 1px rgba(141, 147, 162, 0.25);
  border-radius: 4px;
  padding: 16px;
  column-gap: var(--filter-column-gap);
  row-gap: var(--filter-row-gap);
  margin-bottom: 12px;
  
  ${lessThan('md')} {
   flex-wrap: wrap;
  }
  
  ${lessThan('sm')} {
    --filter-column-gap: 12px;
    column-gap: 12px;
    padding: 12px;
  };
`;

const SCArrowDownIcon = styled(ArrowIcon)`
  font-size: 12px;
  color: var(--primary-color);
  transform: rotate(90deg);
  margin-left: 6px;
`;

const SCBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-shrink: 0;
  flex-grow: 0;
  column-gap: var(--filter-column-gap);
  row-gap: var(--filter-row-gap);
  
  ${lessThan('md')} {
    flex-grow: 1;
    flex-shrink: 1;
  }
`;
