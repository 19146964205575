import React, { HTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import { Dropdown } from '~/atomic/atom/Dropdown';
import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { PaperStyle } from '~/atomic/atom/PaperStyle';
import { TextRegStyle } from '~/atomic/Typography';
import { useOutsideClick } from '~/lib/hooks/useOutsideClick';

export interface SortTemplateProps <T extends string> {
  bookSorting: T;
  sortingOptions: Record<T, string>;
  onChangeBookSort(value: T): void;
}

export const SortTemplate = <T extends string>({
  bookSorting, sortingOptions, onChangeBookSort, ...props
}: SortTemplateProps<T> & HTMLAttributes<HTMLDivElement>) => {
  const [isVisible, setIsVisible] = useState(false);

  const refRegister = useOutsideClick({
    callback: () => {
      setIsVisible(false);
    },
    isActive: isVisible,
  });

  const displayedOptions = { ...sortingOptions };

  delete displayedOptions[bookSorting];

  return (
    <SCDropdownButton ref={refRegister} {...props}>
      <Dropdown
        trigger={['click']}
        onOpenChange={(isOpen) => {
          if (isOpen) {
            setIsVisible(true);
          }
        }}
        placement="bottomCenter"
        open={isVisible}
        overlay={(
          <SCBookSortOverlay ref={refRegister}>
            {Object.entries<string>(displayedOptions).map(([key, value]) => (
              <SCActionButton
                key={key}
                onClick={() => {
                  setIsVisible(false);
                  onChangeBookSort(key as T);
                }}
              >
                <span>{value}</span>
              </SCActionButton>
            ))}
          </SCBookSortOverlay>
        )}
      >
        <SCActionButton data-active>
          <span>{sortingOptions[bookSorting]}</span>
          {' '}
          {isVisible ? (
            <SCArrowUpIcon />
          ) : (
            <SCArrowDownIcon />
          )}
        </SCActionButton>
      </Dropdown>
    </SCDropdownButton>
  );
};

const SCBookSortOverlay = styled.div`
  ${PaperStyle};
  padding: 20px;

  .ant-checkbox-group {
    display: grid;
    grid-row-gap: 18px;
    height: 100%;

    .ant-checkbox-wrapper {
      span:last-child {
        ${TextRegStyle};
      }
    }
  }
`;

const SCDropdownButton = styled.div`
  display: flex;
`;

export const SCActionButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  

  .anticon {
    width: 28px;
    height: 24px;
    position: relative;

    svg {
      position: relative;
      top: 50%;
      transform: translateY(-65%);
    }
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &[data-active] {
    span {
      color: var(--primary-color);
    }
  }

  span {
    ${TextRegStyle};
    color: var(--black-color);

    &:hover {
      color: var(--primary-hover-color);
    }
  }

  &:hover {
    color: var(--primary-hover-color);
  }

  &:hover > div {
    background-color: var(--primary-hover-color);
  }
`;

const SCArrowUpIcon = styled(ArrowIcon)`
  font-size: 12px;
  color: var(--primary-color);
  transform: rotate(-90deg);
  margin-left: 6px;
`;

const SCArrowDownIcon = styled(ArrowIcon)`
  font-size: 12px;
  color: var(--primary-color);
  transform: rotate(90deg);
  margin-left: 6px;
`;
