import React from 'react';
import styled from 'styled-components';

import { ListenIcon } from '~/atomic/atom/icon/ListenIcon';
import { OpenBookIcon } from '~/atomic/atom/icon/OpenBookIcon';
import { TextRegStyle } from '~/atomic/Typography';
import { lessThan } from '~/lib/mediaQuery';

export interface SwitchWithAudiobookFilterProps extends React.HTMLAttributes<HTMLDivElement> {
  withAudiobook: boolean;
  onSwitch: (value: boolean) => void;
}

export const SwitchWithAudiobookFilter = ({
  withAudiobook, onSwitch, ...props
}: SwitchWithAudiobookFilterProps) => {
  return (
    <SCSwitchBookType {...props}>
      <SCItem
        onClick={() => onSwitch(false)}
        data-is-active={!withAudiobook}
      >
        <SCOpenBookIcon />
        Читать
      </SCItem>
      <SCItem
        onClick={() => onSwitch(true)}
        data-is-active={withAudiobook}
      >
        <SCListenIcon />
        Слушать
      </SCItem>
    </SCSwitchBookType>
  );
};

const SCSwitchBookType = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 240px;
  
  ${lessThan('xs')} {
    max-width: none;
  }
`;

const SCItem = styled.div`
  ${TextRegStyle};
  color: var(--black-color);
  background: var(--white-color);
  border: 1px solid var(--border-color);
  padding: 10px 16px;
  border-radius: 2px;
  width: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  &[data-is-active="false"] {
    background: var(--gray6-color);
  }

  ${lessThan('sm')} {
    width: 100%;
    justify-content: center;
  }
`;

const SCOpenBookIcon = styled(OpenBookIcon)`
  font-size: 18px;
  color: var(--black-color);
  margin-right: 10px;
`;

const SCListenIcon = styled(ListenIcon)`
  width: 20px;
  height: 20px;
  fill: var(--black-color);
  margin-right: 10px;
`;
