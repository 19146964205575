import React from 'react';
import styled from 'styled-components';

import { Switch } from '~/atomic/atom/Switch';
import { TextRegStyle } from '~/atomic/Typography';

export type SwitchWithAbonnementFilterProps = {
  isShowWithAbonnement?: boolean;
  withAbonnement?: boolean;
  onChangeWithAbonnement: (isWithAbonnement: boolean) => void;
};

export const SwitchWithAbonnementFilter = ({
  withAbonnement, onChangeWithAbonnement, isShowWithAbonnement, ...props
}: SwitchWithAbonnementFilterProps & React.HTMLAttributes<HTMLDivElement>) => {
  if (!isShowWithAbonnement) return null;

  return (
    <SCSwitchBookType {...props}>
      <Switch
        checked={withAbonnement}
        onChange={onChangeWithAbonnement}
      />
      <SCSwitchText>
        По абонементу
      </SCSwitchText>
    </SCSwitchBookType>
  );
};

const SCSwitchBookType = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
`;

const SCSwitchText = styled.span`
  ${TextRegStyle};
  color: var(--black-color);
`;
