import { Dropdown } from 'antd';
import Link from 'next/link';
import React, { useState } from 'react';
import styled from 'styled-components';

import { BookTag } from '~/api/book/bookTagApiTypes';
import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { TextRegStyle, TextSmallStyle } from '~/atomic/Typography';

export interface CatalogFilterTagsProps {
  currentTagSlug: string;
  tags: BookTag[];
}

export const CatalogFilterTags = ({ tags, currentTagSlug }: CatalogFilterTagsProps) => {
  const [visible, changeVisible] = useState(false);
  const isHaveActiveTag = tags?.some((tag) => currentTagSlug === tag?.slug);

  if (!tags?.length) return null;

  return (
    <SCDropdownButton>
      <Dropdown
        onOpenChange={changeVisible}
        open={visible}
        trigger={['click']}
        placement="bottomCenter"
        overlayStyle={{
          width: '360px',
          background: '#fff',
          border: '1px solid var(--bg-color)',
          boxSizing: 'border-box',
          boxShadow: '0px 3px 5px rgba(53, 63, 72, 0.25)',
          borderRadius: '4px',
          padding: '8px',
        }}
        overlay={(
          <>
            <SCBookSortOverlay>
              {tags?.map((tag) => (
                <SCActionButton
                  key={tag.id}
                >
                  <Link
                    href={currentTagSlug === tag.slug ? tag.withoutUrl : tag.url}
                    as={currentTagSlug === tag.slug ? tag.withoutUrl : tag.url}
                    passHref
                    prefetch={false}
                  >
                    <SCTag
                      key={tag.slug}
                      data-is-active={currentTagSlug === tag.slug}
                      rel="nofollow"
                    >
                      {tag.name}
                    </SCTag>
                  </Link>
                </SCActionButton>
              ))}
            </SCBookSortOverlay>
            {isHaveActiveTag
              ? (
                <Link
                  href={tags[0]?.withoutUrl}
                  as={tags[0]?.withoutUrl}
                  passHref
                  prefetch={false}
                >
                  <SCCancelButton
                    onClick={() => {
                      changeVisible(false);
                    }}
                  >
                    Отменить выбор
                  </SCCancelButton>
                </Link>
              ) : null}
          </>
        )}
      >
        <SCActionButton data-is-active={isHaveActiveTag}>
          Теги
          {' '}
          <SCArrowDownIcon />
        </SCActionButton>
      </Dropdown>
    </SCDropdownButton>
  );
};

const SCBookSortOverlay = styled.div` && {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
`;

const SCTag = styled.a`
  ${TextSmallStyle};
  color: var(--black-color);
  padding: 2px 10px;
  border-radius: 20px;

  &:hover {
    color: rgba(0,0,0,.65);
  }

  &[data-is-active="true"] {
    border: 1px solid #d9d9d9;
  }
`;

const SCDropdownButton = styled.div`
  display: flex;
  position: relative;
`;

export const SCActionButton = styled.strong`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  ${TextRegStyle};
  color: var(--primary-hover-color);

  &[data-is-active="true"] {
    &:before {
      display: block;
      content: '';
      position: absolute;
      left: -9px;
      top: 0;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background-color: var(--primary-color);
    }
  }
`;

const SCArrowDownIcon = styled(ArrowIcon)`
  font-size: 12px;
  color: var(--primary-color);
  transform: rotate(90deg);
  margin-left: 6px;
`;

const SCCancelButton = styled.a`
  ${TextSmallStyle};
  color: var(--primary-color);
  display: block;
  text-align: center;
  cursor: pointer;
`;
