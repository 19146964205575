import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import { BookTypeEnum, CompleteStatusEnum } from '~/api/book/bookApiTypes';
import { Checkbox } from '~/atomic/molecula/Checkbox';
import { Select, SelectOption } from '~/atomic/molecula/Select';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { lessThan } from '~/lib/mediaQuery';
import { useAppSelector } from '~/store';

type CompleteStatusTypes = {
  isShowCompleteStatus?: boolean;
  completeStatus?: CompleteStatusEnum;
  onChangeCompleteStatus?(value: CompleteStatusEnum): void;
};

type BookTypeTypes = {
  isShowBookType?: boolean;
  bookType?: BookTypeEnum;
  onChangeBookType?(value: BookTypeEnum): void;
};

type IsOnlyFreeTypes = {
  isShowOnlyFree?: boolean;
  isOnlyFree?: boolean;
  isOnlyFreeUrl?: string;
  onChangeIsOnlyFree?: (isOnlyFree: boolean) => void;
};

type IsCanDownloadTypes = {
  isShowCanDownload?: boolean;
  isCanDownload?: boolean;
  isCanDownloadUrl?: string;
  onChangeIsCanDownload?: (isCanDownload: boolean) => void;
};
export type CatalogOtherFiltersProps = {
  isAddFilterToParams?: boolean;
} &
CompleteStatusTypes &
BookTypeTypes &
IsOnlyFreeTypes &
IsCanDownloadTypes;

export const CatalogOtherFilters = ({
  isAddFilterToParams = true,
  isOnlyFree,
  isOnlyFreeUrl,
  onChangeIsOnlyFree,
  isCanDownload,
  isCanDownloadUrl,
  onChangeIsCanDownload,
  bookType,
  onChangeBookType,
  completeStatus,
  onChangeCompleteStatus,
  isShowBookType = false,
  isShowOnlyFree = false,
  isShowCanDownload = false,
  isShowCompleteStatus = false,
}: CatalogOtherFiltersProps) => {
  const isMobileSize = useAppSelector(isMobileSizeSelector);

  return (
    <SCMoreFilters>
      <SCCheckboxWrapper>
        {isShowOnlyFree && isAddFilterToParams && (
          <Link
            href={isOnlyFreeUrl}
            passHref
            prefetch={false}
          >
            <a>
              <Checkbox
                checked={isOnlyFree}
                title="Только бесплатные"
              />
            </a>
          </Link>
        )}
        {isShowCanDownload && isAddFilterToParams && (
          <Link
            href={isCanDownloadUrl}
            passHref
            prefetch={false}
          >
            <a>
              <Checkbox
                checked={isCanDownload}
                title="Можно скачать"
              />
            </a>
          </Link>
        )}
        {isShowOnlyFree && !isAddFilterToParams && (
          <Checkbox
            checked={isOnlyFree}
            onChange={(e) => onChangeIsOnlyFree(e.target.checked)}
            title="Только бесплатные"
          />
        )}
        {isShowCanDownload && !isAddFilterToParams && (
          <Checkbox
            checked={isCanDownload}
            onChange={(e) => onChangeIsCanDownload(e.target.checked)}
            title="Можно скачать"
          />
        )}
      </SCCheckboxWrapper>
      <SCSelectorWrapper>
        {isShowBookType && (
          <SCBookTypeSelect
            value={bookType}
            onChange={onChangeBookType}
          >
            <SelectOption
              value={undefined}
              label="Все"
            >
              Все
            </SelectOption>
            <SelectOption
              key={BookTypeEnum.Novel}
              value={BookTypeEnum.Novel}
              label="Романы"
            >
              Романы
            </SelectOption>
            <SelectOption
              key={BookTypeEnum.Story}
              value={BookTypeEnum.Story}
              label="Рассказы"
            >
              Рассказы
            </SelectOption>
          </SCBookTypeSelect>
        )}
        {isShowCompleteStatus && (
          <SCStatusSelect
            value={completeStatus}
            onChange={onChangeCompleteStatus}
          >
            <SelectOption
              value={undefined}
              label={isMobileSize ? 'Все книги' : 'Все'}
            >
              {isMobileSize
                ? 'Все книги'
                : 'Все'}

            </SelectOption>
            <SelectOption
              key={CompleteStatusEnum.Writing}
              value={CompleteStatusEnum.Writing}
              label="В процессе"
            >
              В процессе
            </SelectOption>
            <SelectOption
              key={CompleteStatusEnum.Complete}
              value={CompleteStatusEnum.Complete}
              label="Полностью"
            >
              Полностью
            </SelectOption>
          </SCStatusSelect>
        )}
      </SCSelectorWrapper>
    </SCMoreFilters>
  );
};

const SCMoreFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SCCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SCSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SCStatusSelect = styled(Select)`
  min-width: 150px;
  width: 100%;
  max-height: 44px;

  .ant-select-selection-item {
    color: var(--black-color);
    line-height: 42px !important;
  }

  .ant-select-selector {
    padding-left: 70px !important;
    height: 44px !important;

    &:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      font-family: var(--main-font);;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-color);
      content: 'Статус:';
    }
  }

  ${lessThan('sm')} {
    width: 100%;
    max-width: 100%;
    min-width: 100px;

    .ant-select-selector {
      padding-left: 16px !important;

      &:before {
        display: none;
      }
    }
  }
`;

const SCBookTypeSelect = styled(Select)`
  .ant-select-selection-item {
    color: var(--black-color);
    line-height: 42px !important;
  }

  .ant-select-selector {
    padding-left: 70px !important;
    height: 44px !important;

    &:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      font-family: var(--main-font);;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-color);
      content: 'Форма:';
    }
  }
`;
