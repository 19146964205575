import React from 'react';

import { BookSortingEnum } from '~/api/book/bookApiTypes';
import { SortTemplate, SortTemplateProps } from '~/atomic/molecula/SortTemplate';

const defaultSortingOptions = {
  [BookSortingEnum.Newest]: 'Новые',
  [BookSortingEnum.Bestseller]: 'Бестселлеры',
  [BookSortingEnum.LastUpdate]: 'Обновленные',
} as Record<BookSortingEnum, string>;

export const BookSort = ({
  bookSorting, onChangeBookSort, sortingOptions = {} as Record<BookSortingEnum, string>, ...props
}: Partial<SortTemplateProps<BookSortingEnum>>) => {
  return (
    <SortTemplate
      sortingOptions={{ ...defaultSortingOptions, ...sortingOptions }}
      bookSorting={bookSorting}
      onChangeBookSort={onChangeBookSort}
      {...props}
    />
  );
};
